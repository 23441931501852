import type { FC, ReactNode } from 'react'
import { forwardRef, useState } from 'react'
import { useTheme } from '@emotion/react'
import styled from '@emotion/styled'

import { useRouter } from 'next/router'

import { AppearanceProvider } from '@segment/matcha/foundations/AppearanceContext'

import Media from '@segment/matcha/components/Media'
import ButtonText from '@segment/matcha/components/ButtonText'
import { Button } from '@segment/matcha/components/Button/Button'
import SegmentLogo from '@segment/matcha/components/SegmentLogo'
import { MenuIcon, CloseIcon } from '@segment/matcha/components/Icons/Common'

import DesktopNavBase, * as DesktopNav from '@segment/matcha/recipes/Navigation/DesktopNavigation'
import * as DesktopNavbar from '@segment/matcha/recipes/Navigation/DesktopNavigation/Navbar'
import MobileNavBase, * as MobileNav from '@segment/matcha/recipes/Navigation/MobileNavigation'
import * as MobileNavbar from '@segment/matcha/recipes/Navigation/MobileNavigation/Navbar'

import type { DSComponentProps } from '@segment/matcha/useDesignTokens'

import getAbsoluteUrl from '@segment/utils/getAbsoluteUrl'

import { usePageConfig } from '@segment/contexts/page'

import useDomainPath from '../../utils/useDomainPath'
import useNavLinks from '../../utils/useNavLinks'

interface BaseNavigationProps {
  aside?: ReactNode
}

export type NavigationProps = DSComponentProps<BaseNavigationProps, { logo: string; mobileLogo: string }>

const BaseNavigation: FC<BaseNavigationProps> = ({ aside = <RegisterButton /> }) => {
  const theme = useTheme()
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const navLinks = useNavLinks()

  return (
    <AppearanceProvider appearance="twilioDark">
      <Media greaterThanOrEqual="lg">
        <DesktopNavBase>
          <DesktopNav.Navbar tokens={{ backgroundColor: theme.palette.twilio.gray[100] }}>
            <DesktopNavbar.Brand href="/">
              <SegmentLogo alt="Twilio, Inc." />
            </DesktopNavbar.Brand>

            <DesktopNavbar.Content role="menubar">
              {navLinks.map((link) => (
                <li key={link.id} role="none">
                  <LinkButton link={link} />
                </li>
              ))}
            </DesktopNavbar.Content>

            <DesktopNavbar.Aside>{aside}</DesktopNavbar.Aside>
          </DesktopNav.Navbar>
        </DesktopNavBase>
      </Media>

      <Media lessThan="lg">
        <MobileNavBase>
          <MobileNav.Navbar>
            <MobileNavbar.Brand href="/">
              <SegmentLogo alt="Twilio, Inc." />
            </MobileNavbar.Brand>

            <MobileNavbar.Button
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              aria-label="Open menu"
              aria-haspopup="true"
              aria-expanded={isMenuOpen}
            >
              <MenuIcon color={theme.palette.neutrals.white[100]} />
            </MobileNavbar.Button>
          </MobileNav.Navbar>
        </MobileNavBase>

        {isMenuOpen && (
          <StyledMobileNavMenu>
            <MobileNav.Navbar tokens={{ backgroundColor: theme.palette.twilio.gray[100] }}>
              <MobileNavbar.Brand href="/">
                <SegmentLogo alt="Twilio, Inc." />
              </MobileNavbar.Brand>

              <MobileNavbar.Button
                onClick={() => setIsMenuOpen(!isMenuOpen)}
                aria-label="Close menu"
                aria-haspopup="true"
                aria-expanded={isMenuOpen}
              >
                <CloseIcon color={theme.palette.neutrals.white[100]} />
              </MobileNavbar.Button>
            </MobileNav.Navbar>

            <MobileNav.Content>
              <Content role="menubar">
                {navLinks.map((link) => (
                  <li key={link.id} role="none">
                    <LinkButton key={link.id} link={link} />
                  </li>
                ))}
              </Content>
            </MobileNav.Content>

            <MobileNav.Footer>{aside}</MobileNav.Footer>
          </StyledMobileNavMenu>
        )}
      </Media>
    </AppearanceProvider>
  )
}

interface LinkButtonProps {
  link: ReturnType<typeof useNavLinks>[number]
}

const useActiveLink = (url: string) => {
  const { pathname } = useRouter()

  return url.endsWith(pathname) || url.endsWith(`${pathname}/`)
}

const LinkButton = forwardRef<HTMLButtonElement, LinkButtonProps>(({ link }, ref) => {
  const pageConfig = usePageConfig()
  const isActive = useActiveLink(link.url)

  return (
    <ButtonText
      hover={isActive}
      ref={ref}
      size="small"
      variant="primary"
      role="menuitem"
      href={getAbsoluteUrl(link.url, pageConfig)}
    >
      {link.title}
    </ButtonText>
  )
})

const RegisterButton: FC = () => {
  const registerLink = useDomainPath(`register`)

  return (
    <Button href={registerLink} color="primary" variant="filled" size="medium">
      Register now
    </Button>
  )
}

const Content = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  padding: 40px 16px;
`

const StyledMobileNavMenu = styled(MobileNav.Menu)`
  background: ${({ theme }) => theme.palette.twilio.gray[100]};
`

export default BaseNavigation
