import type { FC } from 'react'

import styled from '@emotion/styled'

import Typography from '@segment/matcha/components/Typography'

import SectionBase, * as Section from '@segment/matcha/recipes/Section'

import LogoCarouselBase, * as LogoCarousel from '@segment/matcha/recipes/LogoCarousel'

import { carouselLogosMono } from '@segment/templates/LogoCarousel/data'

const copy = {
  heading: `Our virtual summit brings together leaders from:`,
}

const logos = [
  carouselLogosMono.LEVIS,
  carouselLogosMono.GOOGLE_CLOUD,
  carouselLogosMono.STAPLES,
  carouselLogosMono.ALLERGAN,
  carouselLogosMono.INTUIT,
  carouselLogosMono.DIGITAL_OCEAN,
  carouselLogosMono.IBM,
  carouselLogosMono.FIDELITY,
  carouselLogosMono.ANHEUSER_BUSCH,
  carouselLogosMono.VMWARE,
  carouselLogosMono.DRIFT,
]

const CarouselSection: FC = () => {
  return (
    <SectionBase variant="noCopy">
      <SectionContents>
        <Heading variant="h4" tag="h2">
          {copy.heading}
        </Heading>
        <StyledLogoCarousel>
          <LogoCarousel.LogoTrack icons={logos} />
        </StyledLogoCarousel>
      </SectionContents>
    </SectionBase>
  )
}

const SectionContents = styled(Section.Contents)`
  display: grid;
  text-align: center;
  row-gap: 32px;

  ${({ theme }) => theme.breakpoints.up(`lg`)} {
    row-gap: 48px;
  }
`

const StyledLogoCarousel = styled(LogoCarouselBase)`
  row-gap: 0;
  grid-template-rows: 24px;
  padding: 0;
`

const Heading = styled(Typography)`
  margin: 0;
  display: block;
`

export default CarouselSection
