import styled from '@emotion/styled'
import { useState, type FC, useEffect } from 'react'
import type { AnimationItem } from 'lottie-web'

import Button from '@segment/matcha/components/Button'

import CopyTextBase, * as CopyText from '@segment/matcha/recipes/CopyText'
import SectionBase, * as Section from '@segment/matcha/recipes/Section'

import getAbsoluteUrl from '@segment/utils/getAbsoluteUrl'
import useIsInView from '@segment/utils/useInView'
import useLottie from '@segment/utils/useLottie/useLottie'

import { usePageConfig } from '@segment/contexts/page'

import useDomainPath from '../../utils/useDomainPath'

import jsonUrl from './assets/cpd_week_lottie_footer.json.data'

const copy = {
  heading: `Tune into CDP Week 2024 today`,
}

const BUTTON_TEXT = `Register now`

const SaveYourSpot: FC = () => {
  const pageConfig = usePageConfig()
  const [animationData, setAnimationData] = useState<Promise<Response>>()
  const registerLink = useDomainPath(`register`)

  const [animation, containerRef] = useLottie<AnimationItem, HTMLDivElement>({
    options: {
      animationData,
      autoplay: true,
      loop: false,
    },
  })

  useEffect(() => {
    setAnimationData(fetch(jsonUrl))
  }, [])

  const isInView = useIsInView(containerRef)

  useEffect(() => {
    if (isInView && animation) {
      if (animation?.isPaused) {
        animation?.playSegments([0, animation.totalFrames], true)
        animation.addEventListener(`enterFrame`, () => {
          if (animation.currentFrame >= 59) {
            animation?.playSegments([60, animation.totalFrames], false)
          }
        })
      }
    } else {
      animation?.stop()
    }
  }, [animation, isInView])

  return (
    <Gradient>
      <StyledSection variant="centered">
        <Section.Copy>
          <CopyTextBase centered={true}>
            <CopyText.Heading tag="h2">{copy.heading}</CopyText.Heading>
          </CopyTextBase>
        </Section.Copy>
        <Section.Contents>
          <ButtonWrapper>
            <Button variant="filled" color="primary" size="large" href={getAbsoluteUrl(registerLink, pageConfig)}>
              {BUTTON_TEXT}
            </Button>
          </ButtonWrapper>
        </Section.Contents>
      </StyledSection>
      <Animation ref={containerRef} />
    </Gradient>
  )
}

const Animation = styled.div``

const Gradient = styled.div`
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.24) 0%, rgba(0, 0, 0, 0.24) 100%),
    ${({ theme }) => theme.palette.cdpWeek2023.gray[100]};
`

const StyledSection = styled(SectionBase)`
  ${Section.Wrapper} {
    row-gap: 40px;

    ${({ theme }) => theme.breakpoints.up(`lg`)} {
      row-gap: 48px;
    }
  }
`

const ButtonWrapper = styled.div`
  display: grid;
  place-items: center;
  width: 100%;
  margin: 0 auto;
`
export default SaveYourSpot
